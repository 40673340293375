/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../less/cms.less'

const TermsAndConditions = () => {
  return (
    <Layout>
      <SEO title="Terms" />
      <div className="cms-container">
        <h1 className="cms-container__title">Terms of Use</h1>
        <div className="cms-container__wrapper cms-container__wrapper__terms">
          <p>
            <strong>Last updated: January 23, 2025.</strong>
          </p>
          <p>
            Welcome to MealPal! These Terms of Use (“Terms”) are a contract between you and MealPal, Inc. for members in
            the United States and MealPal Canada Limited for members in Canada, (“MealPal” or “we”) and govern your
            access to and use of any MealPal website, mobile application (such as for iPhone or Android) or content, or
            products and/or services made available through MealPal (collectively, the “Site”). Please read these Terms
            carefully before accessing and using the Site.
          </p>
          <ol>
            <li>
              TERMS OF USE
              <ol type="a">
                <li>
                  <strong>Acceptance of Terms.</strong> By accessing and/or using the Site in any manner, including but
                  not limited to visiting or browsing the Site, you accept and agree to be bound by these Terms, as well
                  as our Privacy Policy, which can be found at{' '}
                  <a
                    href={`${process.env.GATSBY_LANDING_PAGE_URL}/privacy-policy/`}
                  >{`${process.env.GATSBY_LANDING_PAGE_URL}/privacy-policy/`}</a>{' '}
                  and is incorporated by reference as if fully set forth herein, just as if you had agreed to them in
                  writing. If you do not agree to these Terms, do not use the Site.
                </li>
                <li>
                  ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT UNDER CERTAIN CIRCUMSTANCES DESCRIBED IN THE
                  ARBITRATION SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
                  INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE
                  ARBITRATION.
                </li>
                <li>
                  <strong>Eligibility.</strong> By using the Site, you represent and warrant that you are at least 13
                  years of age or older. If you are under age 13, you may not, under any circumstances or for any
                  reason, use the Services. The availability of all or part of our Site may be limited based on
                  demographic, geographic, or other criteria as we may establish from time to time. You understand and
                  agree we may disallow you from subscribing to MealPal or may terminate your subscription at any time
                  based on these criteria.
                </li>
                <li>
                  <strong>Amendment of Terms.</strong> MealPal may amend the Terms from time to time. You agree that you
                  will review these Terms, which can be found at{' '}
                  <a
                    href={`${process.env.GATSBY_LANDING_PAGE_URL}/terms-and-conditions/`}
                  >{`${process.env.GATSBY_LANDING_PAGE_URL}/terms-and-conditions/`}</a>
                  , periodically. You may be required to click “accept” or “agree” to show your consent to an amended
                  version of these Terms; otherwise, unless we provide a delayed effective date, all amendments will be
                  effective upon posting of such amended Terms on the Site. You are free to decide whether or not to
                  accept any amended version of the Terms, but your continued access to or use of the Site after an
                  amendment becomes effective constitutes your consent to be bound by the Terms, as amended. Your use of
                  the Site will be subject to the version of the Terms in effect at the time of use.
                </li>
                <li>
                  <strong>Additional Terms.</strong> In addition to these Terms, when using particular plans, offers,
                  promotions, products, services or features, you will also be subject to any additional posted
                  guidelines, or rules applicable to such plan, offer, promotion, product, service or feature, which may
                  be posted and modified from time to time. All such additional terms are hereby incorporated by
                  reference into the Terms, provided that in the event of any conflict between such additional terms and
                  the Terms, the Terms shall control.
                </li>
              </ol>
            </li>
            <li>
              MEALPAL PLATFORM
              <ol type="a">
                <li>
                  <strong>MealPal Platform.</strong> MealPal is a subscription membership that enables MealPal members
                  to reserve and pick up meals offered and prepared by restaurants, food service providers, and chefs
                  that partner with MealPal (“Merchants”). Through the MealPal Site you can access third party products
                  and services. MealPal itself is not a restaurant, chef or food service provider and does not own,
                  operate or control any of the restaurants, chefs, or food services facilities accessible through the
                  Site. MealPal is not responsible for the preparation, safety, or quality of any meals offered by its
                  Merchants, or for verifying the accuracy of any descriptions or representations made by Merchants
                  regarding their ingredients or meals. MealPal is not responsible for the safety or accessibility of
                  any Merchants’ premises, or for the compliance by Merchants of any applicable laws or regulations.
                </li>
                <li>
                  <strong>Membership Plans.</strong> MealPal offers a variety of Membership Plans. Not all plans are
                  available in all markets. MealPal may also offer a number of other Membership Plans, including special
                  promotional plans or memberships, with differing conditions and limitations. We reserve the right to
                  modify, terminate, supplement, or otherwise amend our offered Membership Plans. It is the
                  responsibility of the user to understand which plan they are on and the conditions and limitations of
                  the plan. Some of our most popular plans are listed below. Not all plans are available in all markets
                  or available to all customers.
                  <ol type="i">
                    <li>
                      <strong>30-Day Meal Plans.</strong> MealPal offers 30-day subscription plans (“30-Day Plans”). You
                      may purchase a 30-Day Plan for up to 12 meals per 30-day period (“12 Meal 30-Day Plan”) or up to
                      20 meals per 30-day period (“20 Meal 30-Day Plan”). 30-Day Plans renew automatically upon the use
                      of all of the meals or at the end of the 30 day cycle, whichever comes first. Unused meals do not
                      roll over to the next cycle.
                    </li>
                    <li>
                      <strong>Flex Meal Plans.</strong> MealPal also offers subscription plans which permit you to
                      purchase a fixed number of meals for a fixed price that can be used for a more flexible time
                      period (“Flex Plans”). Flex Plan meals expire one year from the date of purchase. Flex Plans renew
                      automatically upon the use of all of the meals or at the end of the 365 day cycle, whichever comes
                      first. Unused meals do not roll over to the next cycle.
                    </li>
                    <li>
                      <strong>Rollover Meal Plans.</strong> MealPal also offers subscription plans which permit you to
                      purchase a set number of meals that can roll over for a subsequent cycle (“Rollover Plans”).
                      Rollover Plans renew automatically upon the use of all of the meals or upon the end of the 30 day
                      cycle, whichever comes first. At the end of the cycle, unused meals may be rolled over to the next
                      cycle as long as the next cycle is a Rollover plan for at least the same number of meals as the
                      current rollover plan. If a user renews to a rollover plan with a lower number of meals than their
                      prior plan, then the maximum number of unused meals that can rollover is equal to the maximum
                      number of meals on their new rollover plan. Unused meals can only roll over for one cycle.
                    </li>
                    <li>
                      <strong>Credit Plans.</strong> MealPal also offers subscription plans which permit you to purchase
                      a set number of credits that can be used to reserve meals. If you are on a credit plan, you will
                      see varying credit amounts for each meal available. Credit Plans renew automatically at the end of
                      the 30 day cycle or when less than 10 credits remain, whichever comes first. For most Credit
                      Plans, up to 50% of unused credits from the lesser number of total credits on the current or the
                      next plan can rollover. Credit Plan users can’t switch to Meal Based Plans.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Delivery Plans and Delivery Credits.</strong> In certain markets, we may offer the ability to
                  have meals delivered to your home or address.
                  <ol type="i">
                    <li>
                      <strong>Delivery Address.</strong> We do not deliver to all addresses. For a current list of
                      addresses we deliver to, please review:{' '}
                      <a
                        href={`${process.env.GATSBY_LANDING_PAGE_URL}/faq/`}
                      >{`${process.env.GATSBY_LANDING_PAGE_URL}/faq/`}</a>{' '}
                      (U.S.) You may only have meals delivered to a single address per meal per day. For example, you
                      may not have 1 dinner meal delivered to 1 address and another dinner meal delivered to a different
                      address on the same day. You may set deliveries to go to different addresses on different days.
                    </li>
                    <li>
                      <strong>Delivery Windows.</strong> MealPal and MealPal’s delivery partners will make every effort
                      to deliver your meal during the delivery window you have selected. We do not guarantee delivery
                      during a certain time window, and there are no refunds or credits if your delivery is not during
                      the window you desire.
                    </li>
                    <li>
                      <strong>Delivery Pass.</strong> We may offer a Delivery Pass in your market. With the Delivery
                      Pass, you may pay $9.99 in order to unlock complementary delivery from select restaurants each
                      night. MealPal makes no guarantees that there will be complementary delivery restaurants available
                      for your address. The Delivery Pass will renew with your regular MealPal plan. It cannot be
                      prorated. If you want to add a delivery pass when you are already 15 days through your regular 30
                      day MealPal cycle, you will still pay $9.99 for the Delivery Pass, and you will only have the
                      remaining 15 days in your cycle to use it. Unless otherwise stated, the Delivery Pass will
                      automatically renew with your regular MealPal cycle. There are no refunds on the Delivery Pass.
                      The Delivery Pass does not cover delivery from all restaurants.
                    </li>
                    <li>
                      <strong>Delivery Credits.</strong> Whether you have a Delivery Pass or not, you may be able to
                      order meals for delivery to your address by paying a set number of credits for the delivery. These
                      delivery credits will be deducted from your account with your meal reservation. Only certain plans
                      and certain markets are eligible for Delivery via Delivery Credits. Credits used for a meal
                      delivery are non-refundable.{' '}
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Eligible Meals.</strong> Members may use the meals or credits they purchased to reserve at
                  least 1 available meal per day from a participating Merchant (“Eligible Meals”). Certain dinner plans
                  allow users to reserve multiple meals per day, as noted when you sign up for the plan. The member may
                  use a meal at any Merchant available on our platform and on their plan. Some Merchants will not be
                  available to users on certain plans. If a member purchases both a lunch and a dinner plan, then under
                  the terms of each plan, the member may be entitled to receive up to 2 meals per weekday – i.e., one
                  meal at lunch time and one meal at dinner time. MealPal reserves the right to change from time to time
                  the number of Eligible Meals a member can reserve per cycle, Membership Plan, geographic location,
                  Merchant or otherwise.
                </li>
                <li>
                  <strong>Merchant and Meal Availability and Allocation.</strong> MealPal makes no guarantee about the
                  availability of particular Merchants, meals, menu choices or other inventory, and availability may
                  change over time, including during the course of any given Membership Cycle (defined in Section 3(a)
                  below). Members on different plans may see different Merchants, meals, and availability.
                </li>
                <li>
                  <strong>Meal Modifications.</strong> MealPal Merchants are unable to make any modifications to the
                  meals that they are offering on the platform. Modifications, substitutions and any other changes or
                  requests for changes are strictly forbidden and may result in a termination of your MealPal
                  membership.
                </li>
                <li>
                  <strong>Meal Ingredients.</strong> MealPal makes no guarantee regarding the quality or nature of the
                  ingredients contained in any meal offered by Merchants, or that any listing or description of
                  ingredients for any meal is comprehensive or accurate. You acknowledge and agree that it is your
                  responsibility to take any steps to confirm that you are not allergic to, or intolerant of, any
                  ingredients contained in any meal that you order or use, including inquiring with the Merchant
                  offering that meal directly if necessary. If you are uncertain as to whether any meal offered by or
                  reserved from a MealPal Merchant may contain ingredients to which you are allergic or intolerant, do
                  not order or use such a meal.
                </li>
                <li>
                  <strong>Great Meal Guarantee.</strong> On November 1, 2022, MealPal announced the Great Meal Guarantee
                  for all members on credit-based plans in New York City. All meals ordered through MealPal should be
                  the same portion size as the meal offered by the restaurant at retail, if applicable. If you receive a
                  meal via MealPal that you believe is not the same size as the meal offered at retail, you must take a
                  picture of the meal that clearly shows the issue. The picture must be submitted to hello@mealpal.com
                  within 24 hours of the date of the reservation with details on the issue. All submissions will be
                  reviewed by MealPal, and if it is determined that the meal portion is smaller than the retail rate,
                  the credits will be returned to your account. You will not receive an extension of your cycle. The
                  final review of the portion size is at the sole discretion of MealPal.
                </li>
                <li>
                  <strong>Reservation and Cancellation of Meals.</strong> As a MealPal member you must reserve and
                  cancel your MealPal meals only through the MealPal Site. It is a breach of your MealPal membership
                  terms if you cancel, change, or request a meal directly with a Merchant. It is a breach of your
                  MealPal membership terms to reserve a MealPal meal for use by any third party (including a friend or
                  other MealPal customer, see 2(m) below), and MealPal reserves the right to charge your Payment Method
                  the full retail price charged by Merchant for any such meal. On most plans, meals can be reserved
                  starting at 5:00pm local time in the location you select at checkout (“Local Time”) on the evening
                  before the meal will be received. All lunch meals must be reserved by 1:30pm local time and all dinner
                  meals must be reserved by 4:00pm local time on the day when the meal will be received (“Reservation
                  Deadline”). If a meal is not booked by the Reservation Deadline, the member will not be eligible to
                  receive a meal on that day. There are no refunds for meals not received or not ordered in time or for
                  when not all meals in a cycle are used. Meals offered in connection with a MealPal Membership Plan
                  have no specific cash value, and do not rollover to future Membership Cycles if not used, unless
                  otherwise stated by the plan type (defined in Section 2(b) above). If you cancel a lunch meal before
                  10:30am local time, or a dinner meal before 4:00pm local time, on the weekday the meal would have been
                  received, you will have the opportunity to reserve another meal before 1:30pm for lunch or 4:00pm for
                  dinner on that weekday. If you cancel a lunch meal after 10:30am, or a dinner meal after 4:00pm, on
                  the weekday the meal is to be received, that meal will count toward your total meals allocation for
                  your current Membership Cycle.
                </li>
                <li>
                  <strong>Trials.</strong> From time to time we may offer a trial membership that includes standard
                  access to the Site during the trial period. Unless otherwise communicated, a trial begins at the
                  moment of sign up and ends at 11:59am Local Time. A one week trial ends on the fifth business day of
                  the trial (e.g., if you sign up on a Sunday or Monday, the last day of your trial membership would be
                  Friday of that week, assuming there are no Holidays as defined below) – however, you may still pick up
                  a meal you have previously ordered on that final trial day, even if the meal pickup is scheduled after
                  11:59am Local Time. Unless we expressly communicate otherwise, trial memberships are only available to
                  new members that have never had a MealPal account before, are not transferable, may not be combined
                  with other offers or redeemed for cash and are void where prohibited.
                  <strong>
                    {' '}
                    If you are not a new MealPal member or have previously redeemed a MealPal trial, then your trial
                    membership automatically will convert to a regular 12 Meal 30-Day Plan (or the equivalent for the
                    market you bought the trial in) at the applicable price, and you will begin, and be charged for,
                    your first Membership Cycle on that day (see Section 3 below for more details), and you will be able
                    to order a meal for the following day. If you do not cancel your trial membership before 12:00pm
                    Local Time on the last day of the trial membership, then your trial membership automatically will
                    convert to a regular membership. Unless otherwise specified, trial memberships will convert to 12
                    Meal 30-Day Plan (or the equivalent for the market you bought the trial in) at the applicable price,
                    and you will begin, and be charged for, your first Membership Cycle on that day (see Section 3 below
                    for more details),
                  </strong>
                  and you will be able to order a meal for the following day. You may cancel your trial membership by a)
                  emailing <a href="mailto:hello@mealpal.com">hello@mealpal.com</a> with “Cancel Trial” in the subject
                  line; or b) if available for your account, by accessing “My Account” from the MealPal Menu dropdown.
                  To cancel your trial membership through My Account, click “Change Plan for Next Cycle”; then “Hold or
                  Cancel My Account”; and then “Cancel Account”. If you cancel your trial membership and are
                  inadvertently enrolled automatically into, and charged for, a Membership Plan, please contact us by
                  emailing
                  <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>. Members that cancel and do not convert to a
                  regular membership may not reserve meals taking place after the end of the trial membership period
                  (even if booking occurred before the end of the applicable trial period).
                </li>
                <li>
                  <p>
                    <strong>Gifts and Promotions.</strong> From time to time we may make available gift cards for
                    MealPal membership, or other types of promotions or promotional plans (including through the use of
                    promotional codes or those provided as part of a third party promotion). Gift cards, promotions and
                    promotional plans may only be redeemed as described, and may be subject to additional or different
                    terms. Unless otherwise expressly communicated to you in connection with your redemption, gift
                    cards, promotions and promotional plans are (i) only available to new customers that have never had
                    a MealPal account before, and (ii) may only be redeemed once, upon initial sign-up for your first
                    MealPal membership. MealPal has the right to charge your Payment Method the full retail value of any
                    gift card, promotion, or promotional plan that is redeemed by you more than once, or otherwise than
                    as described in the specifics of the gift card, promotion, or promotional plan. For example, if you
                    redeem a promotion offering $40 off a new MealPal Membership Plan at any time after your initial
                    signup for your first MealPal membership, MealPal may charge you $40 in addition to any fees and
                    charges applicable to your membership. Gift cards, promotions, and promotional plans are not
                    transferable, cannot be redeemed for cash, and may not be combined with other offers and are void
                    where prohibited. To be eligible for certain promotions, you must enroll in a 20 Meal 30-Day Plan.
                    In order to receive a promotion, you may be required to provide and verify your cell phone number.
                    The cell phone number you verify must be a unique cell phone number that is not associated with
                    another account in order to receive the promotion. We may also review any other aspects of your
                    account to determine if it is a new customer account or not, and the determination is at MealPal’s
                    discretion.
                  </p>
                  <p>
                    From time to time we may also make available promotions for referring others to the MealPal service.
                    All referrals require successful validation by MealPal to confirm eligibility. Confirmation of
                    eligibility may include (but is not limited to) validation of email, phone number, credit card,
                    name, device ID, and other customer attributes. If a referral does not reserve a meal within the
                    first 30 days of their membership, they will not be counted as a referral. Final eligibility and
                    qualification for all referrals is at the sole discretion of MealPal.
                  </p>
                  <p>
                    If you have created multiple accounts using different email addresses and/or associated the same
                    phone number with multiple email accounts, it is possible that you will receive a marketing
                    promotion that you are not eligible for. As noted in section 2N, it is a violation of these terms to
                    create multiple MealPal accounts.
                  </p>
                  <p>Final eligibility for all marketing promotions is at the sole discretion of MealPal.</p>
                </li>
                <li>
                  <strong>Holidays.</strong> We observe a limited number of holidays each year. For a current list of
                  observed holidays (each a “Holiday”) please review:
                  <a
                    href={`${process.env.GATSBY_LANDING_PAGE_URL}/faq/`}
                  >{`${process.env.GATSBY_LANDING_PAGE_URL}/faq/`}</a>{' '}
                  (U.S.) and
                  <a
                    href={`${process.env.GATSBY_LANDING_PAGE_URL}/ca/faq/`}
                  >{`${process.env.GATSBY_LANDING_PAGE_URL}/ca/faq/`}</a>{' '}
                  (Canada). On Holidays, you will not be able to reserve meals on the Site. Occasionally, multiple
                  Holidays will be included in a given 30-day billing cycle. During these cycles it is possible that the
                  maximum number of meals you can reserve on the 20 Meal 30-Day Plan will be fewer than 20 meals. The
                  total price for the 20 Meal 30-Day Plan during these cycles does not change. You will not receive a
                  refund for any of your meals. You will not be able to pick up multiple meals on any day.
                </li>
                <li>
                  <strong>Your Home City.</strong> The rate and currency for meals and for taxes and fees may vary by
                  city. Your home city will be determined based on the market where you have most of your meals during
                  any given cycles. If you are charged at the beginning of your cycle for a city that has a lower rate
                  than what ends up being your home city during that cycle, then you will be charged the difference at
                  the end of that cycle. There are no refunds for using MealPal in a less expensive home city.
                </li>
                <li>
                  <strong>Use of MealPal.</strong> Your MealPal membership is personal to you and not for the use or
                  benefit of any third party, and you agree not to share your account with anyone else, or to create
                  more than one account. Members cannot transfer or gift meals to third parties, including other MealPal
                  members. MealPal may not be used for commercial purposes. To use your MealPal membership you must have
                  access to the Internet. We continually update and test various aspects of the MealPal platform. We
                  reserve the right to, and by using our service you agree that we may, include you in or exclude you
                  from these tests without notice.
                </li>
                <li>
                  <strong>In order to pick up your meal</strong>, you must have a mobile device that supports the latest
                  version of the MealPal Android App or iOS App. The MealPal Android App or iOS App must be installed on
                  your mobile device, and you must have a working camera. To pick up your reserved meal, you will be
                  required to open the app, log in to your account, and scan the QR code found at the restaurant.
                  Following the scan, you must then show the confirmation success screen to the merchant. MealPal
                  reserves the right to modify this pickup process as required by the restaurant and/or MealPal.
                </li>
                <li>
                  <strong>In order to use certain features on the MealPal platform</strong>, you are required to have a
                  mobile device. MealPal does not warrant that the Platform will be compatible or interoperable with
                  your mobile device. The Platform requires and uses data access and text messaging capabilities.
                  Carrier rates for data and text messaging may apply, and you are responsible for all of these charges.
                </li>
                <li>
                  <strong>Savings</strong>. Many MealPal members save money by using MealPal. Savings amounts shown are
                  based on the price the member paid per meal (or per credit if on a Credit Plan) compared to the retail
                  price of the meal, as provided by the restaurant. Savings may be understated for accounts that started
                  prior to 1/1/2021.
                </li>
              </ol>
            </li>
            <li>
              BILLING
              <ol type="a">
                <li>
                  <strong>Membership Cycles.</strong>
                  <ol type="1">
                    <li>
                      <strong>30-Day Plans.</strong>Your MealPal membership starts on the date that you sign up for a
                      Membership Plan and submit payment via a current, valid, accepted method of payment, which we may
                      update from time to time (your “Payment Method”). Unless we otherwise communicate a different time
                      period to you at the time of sign up (such as a multi-cycle or other type of commitment plan),
                      your initial MealPal Membership Plan cycle (“Membership Cycle”), and any subsequent Membership
                      Cycle, will end on the 30th day or upon use of all of the meals, whichever comes first.{' '}
                      <strong>
                        Upon the end of each Membership Cycle, your membership will automatically renew for another
                        Membership Cycle, and we will automatically, and without further authorization from you, bill
                        your Payment Method for another Membership Cycle, unless and until your membership is cancelled
                        or terminated. (For example, if you purchase a MealPal 12 Meal 30-Day Plan subscription on March
                        5, and you don’t consume all of the meals, your membership will automatically renew 30 days
                        later on April 4; if you purchase a MealPal 12 Meal 30-Day Plan subscription on April 4 and you
                        consume the 12th meal on April 25th, your membership will automatically renew on April 25th).
                      </strong>
                    </li>
                    <li>
                      <strong>Flex Plans.</strong>Your MealPal membership starts on the date that you sign up for a Flex
                      Plan and submit payment via your Payment Method. Your Membership Cycle will end 365 days after
                      your purchase or upon use of all of the meals, whichever comes first.
                      <strong>
                        Upon the end of each Membership Cycle, your membership will automatically renew for another
                        Membership Cycle, and we will automatically, and without further authorization from you, bill
                        your Payment Method for another Membership Cycle, unless and until your membership is cancelled
                        or terminated.
                      </strong>
                    </li>
                    <li>
                      <strong>Rollover Plans.</strong>Your MealPal membership starts on the date that you sign up for a
                      Rollover Plan and submit payment via your Payment Method. Your Membership Cycle will end 30 days
                      after your purchase or upon use of all of the meals, whichever comes first.
                      <strong>
                        Upon the end of each Membership Cycle, your membership will automatically renew for another
                        Membership Cycle, and we will automatically, and without further authorization from you, bill
                        your Payment Method for another Membership Cycle, unless and until your membership is cancelled
                        or terminated.
                      </strong>
                    </li>
                    <li>
                      <strong>Credit Plans.</strong> MealPal also offers subscription plans which permit you to purchase
                      a set number of credits that can be used to reserve meals. If you are on a credit plan, you will
                      see varying credit amounts for each meal available. Credit Plans renew automatically at the end of
                      the 30 day cycle or when less than 10 credits remain, whichever comes first. For most Credit
                      Plans, up to 50% or up to 100% of unused credits from the lesser number of total credits on the
                      current or the next plan can rollover. Credit Plan users can’t switch to Meal Based Plans.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Billing.</strong> We use Stripe, a third-party payment processor, to bill your Payment Method
                  for your Membership Plan. The processing of payments will be subject to Stripe’s own terms and
                  conditions (which can be found at{' '}
                  <a href="https://stripe.com/payment-terms/legal">https://stripe.com/payment-terms/legal</a>) and
                  privacy policy (which, for US users, may be found at{' '}
                  <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>, and for Canadian users, may
                  be found at <a href="https://stripe.com/ca/privacy">https://stripe.com/ca/privacy</a>) in addition to
                  these Terms. MealPal exercises no authority or control over Stripe, and is not responsible for error
                  by Stripe. By choosing to sign up for a MealPal Membership Plan, you authorize MealPal, through the
                  Stripe, to charge your Payment Method any applicable fees and charges. MealPal reserves the right to
                  correct any errors or mistakes that Stripe makes even if MealPal has already requested or received
                  payment.
                </li>
                <li>
                  <strong>Recurring Billing.</strong>By signing up for a MealPal membership, you acknowledge that your
                  subscription has both an initial and recurring payment feature, and you accept responsibility for all
                  recurring charges prior to cancellation.
                  <strong>
                    By starting your MealPal membership, you authorize us to charge your Payment Method (i) a one-time
                    fee for your initial Membership Cycle, and, without further notice to you (ii) a recurring fee at
                    the end of each successive Membership Cycle thereafter, at the then-current rate (which may change
                    from time to time), unless and until you have timely cancelled your membership in accordance with
                    Section 3(e) or changed your Payment Method. You acknowledge that the amount billed each cycle may
                    vary for reasons that may include differing amounts due to promotional offers and/or changing or
                    adding a plan, and you authorize us to charge your Payment Method for such varying amounts, which
                    may be billed in one or more charges per Membership Cycle. You also authorize us to charge you any
                    other fees you may incur in connection with your use of the Site, such as any applicable sign-up fee
                    or taxes, as further explained below. We reserve the right to change the timing of our billing (and
                    if we do, we’ll make adjustments to the amounts we charge, as appropriate). Your renewal date may
                    change due to changes in your membership. We reserve the right to correct any billing errors or
                    mistakes made by MealPal, and to charge you for any previous Membership Cycles or other fees
                    incurred by you for which you were not already billed.
                  </strong>
                </li>
                <li>
                  <strong>Break.</strong> In certain markets, you may have the opportunity to select the Break Plan.
                  While on Break, you will get 6 credits for $9 to use over the course of a 30 day cycle. The Break will
                  continue to renew into another 30 day Break Plan (with a subsequent $9 charge and 6 credits) every 30
                  days or when your credit balance drops below 4 credits, whichever comes first. You can rollover unused
                  credits from a break plan to a consecutive Break Plan. You can rollover up to 6 unused credits from a
                  cycle immediately preceding a Break to your Break.
                </li>
                <li>
                  <strong>Billing Holds.</strong> You may put your Membership Plan on hold (“Billing Hold”) at any time
                  for up to ninety (90) days. For a Billing Hold to be effective for your next Membership Cycle, it must
                  be received at least 7 days prior to the end of your then-current membership Cycle in a 30-Day Plan,
                  before you reserve your final meal, or before you fall below 10 credits in your account. To establish
                  a Billing Hold, i) email <a href="mailto:hello@mealpal.com">hello@mealpal.com</a> with “Billing Hold”
                  in the subject line; or ii) access “My Account” from the MealPal Menu dropdown. To establish a Billing
                  Hold in My Account, click “Change Plan for Next Cycle”; then “Hold or Cancel My Account”; and then
                  “Hold Account”. While you are on a Billing Hold, you will not be charged, and your Membership Plan
                  will automatically begin again with a new Membership Cycle commencing on the day that you end your
                  Billing Hold. If you go on hold, and are on one of our rollover or credit plans, any unused meals or
                  credits before you go on hold will not rollover to your cycle when you return from hold.
                </li>
                <li>
                  <strong>
                    Cancellation. You may cancel your membership at any time. For a cancellation to be effective for
                    your next Membership Cycle, it must be received at least 7 days prior to the end of your
                    then-current Membership Cycle or before you reserve your final meal. To cancel a membership, i)
                    email <a href="mailto:hello@mealpal.com">hello@mealpal.com</a> with “Cancel Membership” in the
                    subject line; or ii) access “My Account” from the MealPal dropdown. To cancel your membership in My
                    Account, click “Change Plan for Next Cycle”; then “Hold or Cancel My Account”; and then “Cancel
                    Account”. Your Membership Plan will terminate at the end of that Membership Cycle (you cannot cancel
                    a Membership Plan partway through a Membership Cycle), unless you cancel with fewer than 7 days
                    remaining in a cycle in which case your Membership Plan will terminate at the end of the subsequent
                    Membership Cycle.
                  </strong>{' '}
                  Following any cancellation, you will continue to have access to your subscription through the end of
                  your current prepaid billing period.
                </li>
                <li>
                  <strong>Reaffirmation of Authorization.</strong> Your non-cancellation or continued enrollment in a
                  Membership Plan reaffirms that we are authorized to charge your Payment Method for that Membership
                  Plan. We may submit those charges for payment and you will be responsible for such charges. This does
                  not waive our right to seek payment directly from you. Your charges may be payable in advance, in
                  arrears, per usage, or as otherwise described when you initially selected the Membership Plan.
                </li>
                <li>
                  <strong>Membership Changes.</strong> You may change your current Membership Plan to a different
                  Membership Plan by i) emailing <a href="mailto:hello@mealpal.com">hello@mealpal.com</a> with “Change
                  Membership” in subject line; or ii) accessing “My Account” from the MealPal Menu dropdown. To change
                  your Membership Plan in My Account, click “Change Plan for Next Cycle” and then select from one of the
                  available options. Requests to change your Membership Plan must be received at least 7 days prior to
                  the end of your next Membership Cycle. Membership Plans can only be changed once per billing cycle.
                </li>
                <li>
                  <strong>Payment Method.</strong> You may edit your Payment Method information by i) emailing{' '}
                  <a href="mailto:hello@mealpal.com">hello@mealpal.com</a> with “Payment Method” in subject line (a
                  MealPal representative will contact you back with further direction, please do not send credit card or
                  bank account information to MealPal through email); or ii) accessing “My Account” from the MealPal
                  Menu dropdown. To change your Payment Method in My Account, click on “Update Credit Card” under the
                  “Billing Info” section of “Your Account Information.” If your Payment Method fails, MealPal will lock
                  you out of your account and you must email <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>. A
                  MealPal representative will contact you to obtain and input your new Payment Method information.
                  (Please do not send credit card or bank account information to MealPal through email.) If a payment is
                  not successfully settled, due to expiration, insufficient funds or otherwise, and you do not contact
                  us with your Payment Method information or cancel your account in accordance with Section 3(e), you
                  nonetheless will remain responsible for any uncollected amounts and authorize us to continue billing
                  the Payment Method, as it may be updated, and/or to charge another credit or debit card that remain
                  listed on your account, if any, for any uncollected amounts. This may result in a change to your
                  payment billing dates. If we cannot charge your account, we reserve the right, but are not obligated,
                  to terminate your access to our Site or any portion thereof.
                </li>
                <li>
                  <strong>Refunds.</strong> Generally, our fees (including the fee for your membership and any other
                  fees) <strong>are nonrefundable</strong>, except that we will provide a refund to members for their
                  current prepaid period in the following circumstances: (i) if you are canceling your subscription and
                  request a refund within 5 days of your initial purchase or (ii) if your subscription is canceled prior
                  to the end of a period for which you have incurred a charge, due to your disability or death. In each
                  case we reserve the right to charge a fee to cover the cost of any meals or other services you may
                  have used prior to your cancelation. The current rates that will be charged are $14 USD per meal (plus
                  tax and fees) for any meals reserved in New York, Seattle, and San Francisco, $12 per meal (plus tax
                  and fees) for any meals reserved in Boston, Chicago, Los Angeles, Philadelphia, Portland, or
                  Washington, DC, and $13 CAD per meal (plus tax and fees) for any meals reserved in Toronto (such meal
                  fee charges not to exceed the cost of the subscription itself). Any additional discounts or refunds
                  that we may choose to provide are in our sole discretion and do not entitle you to any future
                  discounts or refunds in the future for similar instances.
                </li>
                <li>
                  <strong>Price Changes.</strong> We reserve the right to adjust pricing at any time. Unless we
                  expressly communicate otherwise, any price changes to your Membership Plan will take effect on your
                  next Membership Cycle upon notice communicated through a posting on the MealPal Site or such other
                  means as we may deem appropriate from time to time, such as email.
                </li>
                <li>
                  <strong>Taxes and fees.</strong> Taxes and fees are charged on all MealPal plans in order to pay taxes
                  to our restaurant partners and to cover other taxes and fees paid by MealPal. In most cases, the taxes
                  and fees match the taxes one would pay at a restaurant in the cities in which MealPal is offered. You
                  may find a current listing of the amount of such fees for your location in our FAQs at
                  <a
                    href={`${process.env.GATSBY_LANDING_PAGE_URL}/faq/`}
                  >{`${process.env.GATSBY_LANDING_PAGE_URL}/ca/faq/`}</a>{' '}
                  (U.S.) and
                  <a
                    href={`${process.env.GATSBY_LANDING_PAGE_URL}/ca/faq/`}
                  >{`${process.env.GATSBY_LANDING_PAGE_URL}/ca/faq/`}</a>{' '}
                  (Canada).
                </li>
                <li>
                  <strong>Holiday Hold Promotion.</strong> For the Holiday Season of 2024, MealPal may offer you an
                  incentive to put your account on hold for the holidays. In order to be eligible for the Holiday Hold
                  Promotion, you must put your account on hold via the Holiday Hold offering in your account (if
                  offered) or by responding directly to an email offering you the Holiday Hold. In order to be eligible
                  for the Holiday Hold, you must resume your account with a regular 30 day cycle for at least 6 meals or
                  35 credits between January 1, 2025 and January 5, 2025. Only one holiday hold promotion per person. As
                  with all holds on the MealPal platform, any unused credits from before the hold begins are not
                  eligible to be rolled over after the Holiday Hold. If the terms of the Holiday Hold Promotion are met,
                  you will receive a $10 promotional gift card or $25 Ticket Rewards gift card (dependent on accepted
                  offer) within 30 days of the completion of your first cycle after the Holiday Hold. Note that Ticket
                  Rewards inventory type and quantity depends on the time of year and Ticket Rewards’ discretion of
                  event availability. MealPal, Inc is not responsible for the available events to redeem on Ticket
                  Rewards. Gift cards are not transferable, cannot be redeemed for cash, and may not be combined with
                  other offers and are void where prohibited. Final eligibility for the Holiday Hold will be determined
                  solely at the discretion of MealPal, Inc. The Holiday Hold can’t be combined with the Holiday Plan or
                  any other promotional offer.
                </li>
                <li>
                  <strong>Holiday Plan Promotion.</strong> For the Holiday Season of 2024, MealPal may offer you an
                  incentive to switch to the 2024 Holiday Plan. The Holiday Plan is for 35 credits for $49 (plus taxes
                  and fees) and will last until January 1, 2025 (or earlier if you elect to start a new cycle before
                  that date). In order to be eligible for the Holiday Plan, you must select the Holiday Plan in your
                  account (if offered) or by responding directly to an email offering you the Holiday Plan. The Holiday
                  Plan is not available in all markets or for all members. In order to be eligible for the Holiday Plan,
                  you must be on a credits plan in NYC for 70 credits or more before renewing to the Holiday Plan. At
                  the end of the Holiday Plan, you must renew to a regular, full-price, 30 day plan of the same credit
                  value plan that you were on before the Holiday Plan (or higher). You must renew from the Holiday Plan
                  between January 1, 2025 and January 5, 2025 to be eligible for the gift card promotion. The length of
                  the Holiday Plan will vary depending on when the Holiday Plan begins. You may not start the Holiday
                  Plan from hold, and the latest date the Holiday Plan can be started is November 30, 2024. If the terms
                  of the Holiday Plan are met, you will receive a $15 promotional gift card or $50 Ticket Rewards gift
                  card (dependent on accepted offer) within 30 days of the completion of your first cycle after the
                  Holiday Plan. Note that Ticket Rewards inventory type and quantity depends on the time of year and
                  Ticket Rewards’ discretion of event availability. MealPal, Inc is not responsible for the available
                  events to redeem on Ticket Rewards. Gift cards are not transferable, cannot be redeemed for cash, and
                  may not be combined with other offers and are void where prohibited. Final eligibility for the Holiday
                  Plan will be determined solely at the discretion of MealPal, Inc. The Holiday Plan can’t be combined
                  with the Holiday Hold or any other promotional offer.
                </li>
              </ol>
            </li>
            <li>
              OTHER FEES
              <ol type="a">
                <li>
                  <strong>Fees We Charge.</strong> Your MealPal membership fee covers your access to Eligible Meals as
                  explained above. We reserve the right to change the policy regarding when we charge fees, to introduce
                  additional fees (such as a sign-up fee, late cancellation fee, or missed meal pick up fee) and to
                  change the amount of any such fees at any time. Additionally, from time to time we may allow you to
                  purchase additional meals, products or services through the Site. If you choose to purchase any of
                  these offerings, you will be responsible to pay the applicable fees in addition to your membership
                  fee.
                </li>
              </ol>
            </li>
            <li>
              TERMINATION OR MODIFICATION BY MEALPAL.
              <ol type="a">
                <li>
                  <strong>Termination or Modification.</strong> You understand and agree that, at any time and without
                  prior notice MealPal may (1) terminate, cancel, deactivate and/or suspend your subscription, your
                  account, any meals reserved, or your access to or use of the Site (or any portion thereof) and/or (2)
                  discontinue, modify, alter, or introduce any aspect, feature or policy of the Site or your membership.
                  This includes the right to terminate or modify any Membership Plan prior to the end of any pre-paid or
                  committed period. Upon any termination, we may immediately deactivate your account and all related
                  information and/or bar any further access to your account information and the Site. Upon any such
                  termination by us without cause, as your sole recourse, we will issue you a pro rata refund of the
                  prepaid portion of your subscription applicable to future unused services (less any fees or costs for
                  meals or services already used). If we determine that you have violated these Terms or otherwise
                  engaged in illegal or improper use of the Site, you will not be entitled to any refund and you agree
                  that we will not be responsible to pay any such refund. You agree that MealPal will not be liable to
                  you or any third party for any termination or modification to the service regardless of the reason for
                  such termination or modification. You acknowledge that your only right with respect to any
                  dissatisfaction with any modification or discontinuation of service made by us is to cancel or
                  terminate your subscription.
                </li>
                <li>
                  <strong>Infringing or Fraudulent Activity.</strong> MealPal does not permit copyright infringing
                  activities and reserves the right to terminate access to the Site and remove all content submitted by
                  any persons who are found to be infringers. Any suspected fraudulent, abusive, or illegal activity
                  that may be grounds for termination of your use of the Site may be referred to appropriate law
                  enforcement authorities. These remedies are in addition to any other remedies MealPal may have at law
                  or in equity.
                </li>
              </ol>
            </li>
            <li>
              CERTAIN ELIGIBILITY CRITERIA; REGISTRATION INFORMATION AND PASSWORD; SITE ACCESS.
              <ol type="a">
                <li>
                  <p>
                    <strong>Eligibility Criteria.</strong> As stated above, the availability of all or part of our Site
                    may be limited based on demographic, geographic, or other criteria as we may establish from time to
                    time. You understand and agree we may disallow you from subscribing to MealPal or may terminate your
                    subscription at any time based on these criteria. The right to access the Site is revoked where
                    these Terms are not in compliance with any laws, rules and regulations applicable to you or use of
                    the Site is prohibited, or to the extent any offering, sale or provision in connection with the Site
                    conflicts with any applicable law, rule or regulation.
                  </p>
                  <p>
                    As stated above, the availability of all or part of our Site may be limited based on demographic,
                    geographic, or other criteria as we may establish from time to time. You understand and agree we may
                    disallow you from subscribing to MealPal or may terminate your subscription at any time based on
                    these criteria. The right to access the Site is revoked where these Terms are not in compliance with
                    any laws, rules and regulations applicable to you or use of the Site is prohibited, or to the extent
                    any offering, sale or provision in connection with the Site conflicts with any applicable law, rule
                    or regulation.
                  </p>
                </li>
                <li>
                  <strong>Subscribing Organizations.</strong> If you are using or opening an account on behalf of a
                  company, entity, or organization (a “Subscribing Organization”), then you represent and warrant that
                  you are an authorized representative of that Subscribing Organization with the authority to bind such
                  organization to these Terms; and agree to be bound by these Terms on behalf of such Subscribing
                  Organization. You agree that any payment method you provide may be used for the Subscribing
                  Organization.
                </li>
                <li>
                  <strong>Account Information.</strong> Account registration requires you to submit to MealPal certain
                  personal information, such as your name, address, and mobile phone number. You are permitted to create
                  only one account. You agree that the information you provide to MealPal at registration and at all
                  other times will be true, accurate, current, and complete. You also agree that you will ensure that
                  this information is kept accurate and up-to-date at all times. When you register, you will be asked to
                  create a password. You are solely responsible for maintaining the confidentiality of your account and
                  password and for restricting access to your computer, and you agree to accept responsibility for all
                  activities that occur under your account.
                </li>
              </ol>
            </li>
            <li>
              PRIVACY
              <p>
                Your privacy is important to MealPal. MealPal’s Privacy Policy, which can be found at
                <a
                  href={`${process.env.GATSBY_LANDING_PAGE_URL}/privacy-policy/`}
                >{`${process.env.GATSBY_LANDING_PAGE_URL}/privacy-policy/`}</a>
                , is hereby incorporated into these Terms by reference. Please read the Privacy Policy carefully for
                information relating to MealPal’s collection, use, and disclosure of your personal information. When you
                make a reservation, the applicable Merchant partner will have access to certain information about you,
                such as your name, so it can provide services to you, communicate with you regarding the meal you
                reserved. Please see the Privacy Policy for more information.
              </p>
            </li>
            <li>
              PROHIBITED CONDUCT
              <p>You promise not to:</p>
              <ol type="i">
                <li>Harass, threaten, or defraud users, members or staff of MealPal or Merchants;</li>
                <li>Make unsolicited offers, advertisements, proposals, or send junk mail or “spam” to users;</li>
                <li>
                  Impersonate another person or access another user’s account with or without that person’s permission;
                </li>
                <li>
                  Share your MealPal account information (including but not limited to passwords) with any third party
                  (including but not limited to other MealPal members) or encourage any other user to do so;
                </li>
                <li>
                  Attempt to redeem any gift card, promotional plan, or promotion more than once or otherwise than as
                  described;
                </li>
                <li>Cancel any MealPal meals directly with a Merchant, rather than through the MealPal Site;</li>
                <li>
                  Misrepresent the source, identity, or content of information transmitted via the Site, including
                  deleting the copyright or other proprietary rights;
                </li>
                <li>
                  Upload material (e.g. virus) that is damaging to computer systems or data of MealPal or users of the
                  Site;
                </li>
                <li>
                  Upload copyrighted material that is not your own or that you do not have the legal right to
                  distribute, display, and otherwise make available to others; or
                </li>
                <li>
                  Upload or send to Site users pornographic, threatening, embarrassing, hateful, racially or ethnically
                  insulting, libelous, or otherwise inappropriate content.
                </li>
              </ol>
            </li>
            <li>
              PROHIBITED USES
              <p>As a condition of your use of the Site, you agree that you: </p>
              <ol type="i">
                <li>Will not use the Site for any purpose that is unlawful or prohibited by these Terms;</li>
                <li>
                  May not use the Site in any manner that, in our sole discretion, could damage, disable, overburden, or
                  impair the Site or interfere with any other party’s use and enjoyment of the Site;
                </li>
                <li>
                  May not attempt to gain unauthorized access to the Site, or any part of the Site, other accounts,
                  computer systems or networks connected to the Site, or any part of them, through hacking, password
                  mining, or any other means or interfere or attempt to interfere with the proper working of the Site or
                  any activities conducted on the Site;
                </li>
                <li>
                  May not remove, circumvent, disable, damage or otherwise interfere with security-related features of
                  the Site, any features that prevent or restrict use or copying of any content accessible through the
                  Site, or any features that enforce limitations on the use of the Site or the content therein;
                </li>
                <li>
                  May not obtain or attempt to obtain any materials or information through any means not intentionally
                  made available through the Site;
                </li>
                <li>
                  Will not modify the Site in any manner or form, nor use modified versions of the Site, including
                  (without limitation) for the purpose of obtaining unauthorized access to the Site; and
                </li>
                <li>
                  Will not use any robot, spider, scraper, or other automated means to access the Site for any purpose
                  without our express written permission or bypass any robot exclusion headers our Site may contain or
                  any other measures we may use to prevent or restrict access to the Site.
                </li>
              </ol>
              <p>
                MealPal reserves the right to refuse service, terminate accounts, remove or edit content, or cancel
                orders in their sole discretion.
              </p>
            </li>

            <li>
              USER SUBMISSIONS OF REVIEWS.
              <ol type="a">
                <li>
                  <strong>General.</strong> The Site provides certain features which enable you, us, and other users to
                  submit, post, and share reviews and other content or materials. These submissions may include without
                  limitation identification of restaurants, meals, and orders you have placed, text, graphic and
                  pictorial works, or any other content submitted or ordered by you and other users through the Site
                  (“User Submissions”). User Submissions are displayed for informational purposes only and are not
                  controlled by MealPal. MealPal does not and cannot guarantee any anonymity or confidentiality with
                  respect to any User Submissions, and strongly recommends that you think carefully about what you
                  upload to the Site. You understand that all User Submissions are the sole responsibility of the person
                  from whom such User Submission originated. This means that you, and not MealPal, are entirely
                  responsible for all User Submissions that you upload, post, e-mail, transmit, or otherwise make
                  available through the Site.
                </li>
                <li>
                  <strong>Right to Remove or Edit User Submissions.</strong> MealPal makes no representations that it
                  will publish or make available on the Site any User Submissions, and reserves the right, in its sole
                  discretion, to refuse to allow any User Submissions on the Site, or to edit or remove any User
                  Submission at any time with or without notice. Without limiting the generality of the preceding
                  sentence, MealPal complies with the Digital Millennium Copyright Act, and will remove User Submissions
                  upon receipt of a compliant takedown notice (see our “Digital Millennium Copyright Act Policy” in
                  Section 11, below).
                </li>
                <li>
                  <strong>License Grant by You to MealPal.</strong> You retain all your ownership rights in original
                  aspects of your User Submissions. By submitting User Submissions to MealPal, you hereby grant MealPal
                  and its affiliates, sublicensees, partners, designees, and assignees of the Site (collectively, the
                  “MealPal Licensees”) a worldwide, non-exclusive, fully paid-up, royalty-free, perpetual, irrevocable,
                  sublicensable, and transferable license to use, reproduce (including by making mechanical
                  reproductions), distribute, modify, adapt, translate, prepare derivative works of, publicly display,
                  publish, publicly perform, and otherwise exploit your User Submissions and derivatives thereof in
                  connection with the Site and MealPal’s (and its successors’) business, including, without limitation,
                  for marketing, promoting, and redistributing part or all of the Site (and derivative works thereof),
                  in any media formats and through any media channels now known or hereafter discovered or developed.
                </li>
                <li>
                  <strong>User Submissions Representations and Warranties.</strong> You understand that when using the
                  Site, you may be exposed to User Submissions from a variety of sources and that MealPal does not
                  endorse and is not responsible for the accuracy, usefulness, safety, or intellectual property rights
                  of or relating to such User Submissions. You further understand and acknowledge that you may be
                  exposed to User Submissions that are inaccurate, offensive, indecent, or objectionable. YOU AGREE TO
                  WAIVE, AND HEREBY DO WAIVE, ANY LEGAL OR EQUITABLE RIGHTS OR REMEDIES YOU HAVE OR MAY HAVE AGAINST
                  MEALPAL WITH RESPECT THERETO.
                </li>
                <li>
                  <strong>Inaccurate or Offensive User Submissions.</strong> You understand that when using the Site,
                  you may be exposed to User Submissions from a variety of sources and that MealPal does not endorse and
                  is not responsible for the accuracy, usefulness, safety, or intellectual property rights of or
                  relating to such User Submissions. You further understand and acknowledge that you may be exposed to
                  User Submissions that are inaccurate, offensive, indecent, or objectionable. YOU AGREE TO WAIVE, AND
                  HEREBY DO WAIVE, ANY LEGAL OR EQUITABLE RIGHTS OR REMEDIES YOU HAVE OR MAY HAVE AGAINST MEALPAL WITH
                  RESPECT THERETO.
                </li>
                <li>
                  <strong>Feedback.</strong> If you provide MealPal with any comments, bug reports, feedback, or
                  modifications proposed or suggested by you to the Site (“Feedback”), MealPal shall have the right to
                  use such Feedback at its discretion, including, but not limited to the incorporation of such suggested
                  changes into the Site. You hereby grant MealPal a perpetual, irrevocable, nonexclusive license under
                  all rights necessary to incorporate and use your Feedback for any purpose.
                </li>
              </ol>
            </li>
            <li>
              DIGITAL MILLENNIUM COPYRIGHT ACT POLICY.
              <p>
                MealPal has adopted the following general policy toward copyright infringement in accordance with the
                Digital Millennium Copyright Act, the text of which may be found at
                <a href="http://lcweb.loc.gov/copyright/legislation/dmca.pdf">
                  http://lcweb.loc.gov/copyright/legislation/dmca.pdf
                </a>
                . The address of the Designated Agent to Receive Notification of Claimed Infringement (“Designated
                Agent”) is listed at the end of this policy.
              </p>
              <p>
                <u>Procedure for Reporting Copyright Infringement:</u> If you believe that material or content residing
                on or accessible through Company’s websites or services infringes a copyright, please send a notice of
                copyright infringement containing the following information to the Designated Agent listed below:
              </p>
              <ol type="1">
                <li>
                  A physical or electronic signature of a person authorized to act on behalf of the owner of the
                  copyright that has been allegedly infringed;
                </li>
                <li>Identification of works or materials being infringed;</li>
                <li>
                  Identification of the material that is claimed to be infringing including information regarding the
                  location of the infringing materials that the copyright owner seeks to have removed, with sufficient
                  detail so that Company is capable of finding and verifying its existence;
                </li>
                <li>
                  Contact information about the notifier including address, telephone number and, if available, e-mail
                  address;
                </li>
                <li>
                  A statement that the notifier has a good faith belief that the material is not authorized by the
                  copyright owner, its agent, or the law; and
                </li>
                <li>
                  A statement made under penalty of perjury that the information provided is accurate and the notifying
                  party is authorized to make the complaint on behalf of the copyright owner.
                </li>
              </ol>
              <p>
                Please contact the Designated Agent to Receive Notification of Claimed Infringement for Company at
                legal@mealpal.com or at:
              </p>
              <p>MealPal Legal Department, 330 7th Ave, New York, NY 10001.</p>
            </li>
            <li>
              OWNERSHIP; PROPRIETARY RIGHTS.
              <p>
                The Site is owned and operated by MealPal. The visual interfaces, graphics, design, compilation,
                information, computer code, products, software (including any downloadable software), services, and all
                other elements of the Site provided by MealPal (“Materials”) are protected by the copyright, trade
                dress, patent, and trademark laws of the United States, Canada, the United Kingdom, Australia, France,
                and other countries, international conventions, and all other relevant intellectual property and
                proprietary rights, and applicable laws. Except for any content uploaded by you, all Materials contained
                on the Site are the copyrighted property of MealPal or its subsidiaries or affiliated companies and/or
                third-party licensors. All trademarks, service marks, and trade names are proprietary to MealPal or its
                affiliates and/or third-party licensors. Except as expressly authorized by MealPal, you agree not to
                sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt,
                create derivative works from, or otherwise make unauthorized use of the Materials. The use, copying,
                sale, leasing, renting, lending, distribution, modification, downloading, creating of derivative works,
                posting or publication by you, directly or indirectly, of any of the Site Materials, or any other use of
                such content, except pursuant to the express limited grant of rights hereunder, is strictly prohibited.
              </p>
            </li>
            <li>
              THIRD-PARTY SITES, PRODUCTS AND SERVICES; LINKS.
              <p>
                The Site may include links or access to, and/or integration with, other web sites or services (“Linked
                Sites”) solely as a convenience or additional service for users. MealPal does not endorse any such
                Linked Sites or the information, material, products, or services contained on other linked sites or
                accessible through other Linked Sites. Furthermore, MealPal makes no express or implied warranties with
                regard to the information, material, products, or services that are contained on or accessible through
                linked sites. ACCESS AND USE OF LINKED SITES, INCLUDING THE INFORMATION, MATERIAL, PRODUCTS, AND
                SERVICES ON LINKED SITES OR AVAILABLE THROUGH LINKED SITES, IS SOLELY AT YOUR OWN RISK.
              </p>
              <p>
                Your correspondence or business dealings with, or participation in promotions of, advertisers found on
                or through the Site are solely between you and such advertiser. YOU AGREE THAT MEALPAL WILL NOT BE
                RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT INCURRED AS THE RESULT OF ANY SUCH DEALINGS OR
                AS THE RESULT OF THE PRESENCE OF SUCH ADVERTISERS ON THE SITE.
              </p>
            </li>
            <li>
              APPLE DEVICE AND APPLICATION TERMS.
              <p>
                If you are accessing the Site via an application on a device provided by Apple, Inc. (“Apple”) or an
                application obtained through the Apple App Store, the following shall apply:
              </p>
              <ol type="i">
                <li>
                  Both you and Apple acknowledge that these Terms are concluded between you and MealPal only, and not
                  with Apple, and that Apple is not responsible for, does not endorse, and is not involved in the
                  application;
                </li>
                <li>
                  The application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable
                  basis, solely to be used in connection with the Site for your private, personal, non-commercial use,
                  subject to all the terms and conditions of these Terms as they are applicable to the Site;
                </li>
                <li>You will only use the application in connection with an Apple device that you own or control;</li>
                <li>
                  You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and
                  support services with respect to the application;
                </li>
                <li>
                  In the event of any failure of the application to conform to any applicable warranty, including those
                  implied by law, you may notify Apple of such failure; upon notification, Apple’s sole warranty
                  obligation to you will be to refund to you the purchase price, if any, of the application;
                </li>
                <li>
                  You acknowledge and agree that MealPal, and not Apple, is responsible for addressing any claims you or
                  any third party may have in relation to the application;
                </li>
                <li>
                  You acknowledge and agree that, in the event of any third party claim that the application or your
                  possession and use of the application infringes that third party’s intellectual property rights,
                  MealPal, and not Apple, will be responsible for the investigation, defense, settlement and discharge
                  of any such infringement claim;
                </li>
                <li>
                  You represent and warrant that you are not located in a country subject to a U.S. Government embargo,
                  or that has been designated by the U.S. Government as a “terrorist supporting” country, and that you
                  are not listed on any U.S. Government list of prohibited or restricted parties;
                </li>
                <li>
                  Both you and MealPal acknowledge and agree that, in your use of the application, you will comply with
                  any applicable third party terms of agreement which may affect or be affected by such use; and
                </li>
                <li>
                  Both you and MealPal acknowledge and agree that Apple and Apple’s subsidiaries are third party
                  beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right
                  (and will be deemed to have accepted the right) to enforce these Terms against you as the third party
                  beneficiary hereof.
                </li>
              </ol>
            </li>
            <li>
              NOTICE.
              <p>
                Except as explicitly stated otherwise, legal notices will be served, with respect to MealPal, on MealPal
                national registered agent, and, with respect to you, to the email address you provide to MealPal during
                the registration process. Notice will be deemed given 24 hours after email is sent, unless the sending
                party is notified that the email address is invalid. Alternatively, we may give you legal notice by mail
                to the address provided during the registration process. In such a case, notice will be deemed given
                three days after the date of mailing.
              </p>
            </li>
            <li>
              DISCLAIMERS; NO WARRANTIES.
              <p>
                MEALS, AND OTHER NON-MEALPAL PRODUCTS AND SERVICES OFFERED VIA THE SITE, ARE OFFERED AND PROVIDED BY
                THIRD PARTIES, NOT MEALPAL. MEALPAL HAS NO CONTROL WHATSOEVER OVER THE COMPOSITION, PREPARATION,
                QUALITY, OR SAFETY OF ANY MEALS OR OTHER NON-MEALPAL PRODUCTS, OR OVER THE PREMISES, SERVICES, OR
                PERSONNEL OF ANY THIRD PARTIES. YOUR SELECTION, COLLECTION, AND USE OF MEALS, YOUR USE OF NON-MEALPAL
                PRODUCTS AND SERVICES, AND YOUR ENGAGEMENT WITH ANY THIRD PARTIES IS SOLELY AT YOUR OWN RISK. IN NO
                EVENT SHALL MEALPAL BE LIABLE FOR ANY ACT, REPRESENTATION, ERROR OR OMISSION BY ANY THIRD PARTY,
                INCLUDING, WITHOUT LIMITATION, ANY WHICH ARISES OUT OF OR IS ANY WAY CONNECTED WITH A MEMBER’S VISIT TO
                A MERCHANT, A MEMBER’S USE OF OR CONSUMPTION OF A MEAL, SERVICE, PRODUCT OR APPOINTMENT MADE THROUGH THE
                SITE, OR THE PERFORMANCE OR NON-PERFORMANCE OF ANY THIRD PARTY PROVIDER IN CONNECTION WITH THE SERVICES.
                MEALPAL IS NOT AN AGENT OF ANY THIRD PARTY PROVIDER.
              </p>
              <p>
                THE SITE AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION
                WITH OR THROUGH THE SITE ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND EITHER
                EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, MEALPAL, ON BEHALF OF
                ITSELF AND ITS SUPPLIERS AND PARTNERS, DISCLAIMS AND EXCLUDES ALL WARRANTIES, WHETHER STATUTORY, EXPRESS
                OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS.
              </p>
              <p>
                WITHOUT LIMITING THE FOREGOING, MEALPAL DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS (I) THAT THE SITE
                AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR
                THROUGH THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, (II) THAT DEFECTS WILL BE CORRECTED, (III) THAT
                THE SITE AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION
                WITH OR THROUGH THE SITE OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS, OR (IV) REGARDING THE USE OF THE SITE AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, OR
                APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE SITE IN TERMS OF CORRECTNESS, ACCURACY,
                RELIABILITY, OR OTHERWISE. ANY MATERIAL OR DATA THAT YOU DOWNLOAD OR OTHERWISE OBTAIN THROUGH THE SITE
                IS AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA
                RESULTING FROM THE DOWNLOAD OF SUCH MATERIAL OR DATA.
              </p>
              <p>
                CERTAIN STATE AND PROVINCE LAWS MAY NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
                EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
              </p>
            </li>
            <li>
              WAIVER AND RELEASE.
              <p>
                You understand and acknowledge that MealPal is not a restaurant or food provider or other service
                provider and that any meals you consume in connection with your MealPal membership are entirely sourced
                and prepared by the applicable Merchant and not in any way by MealPal. Although MealPal endeavors to
                select Merchants that offer inventory that is of high quality, MealPal has no control over, and is in no
                way responsible for, the quality of any meals or service.
              </p>
              <p>
                Therefore, to the fullest extent permitted by law, you release, indemnify, and hold harmless MealPal,
                its parent, subsidiaries or affiliated entities, and each of their respective officers, directors,
                members, employees, consultants, contract employees, representatives and agents, and each of their
                respective successors and assigns, from any and all responsibility, claims, actions, suits, procedures,
                costs, expenses, damages and liabilities arising out of or in any way related to your participation in
                or use of your MealPal membership, including with respect to bodily injury, physical harm, illness,
                death or property damage.
              </p>
              <p>Section 1542 of the California Civil Code provides that:</p>
              <p>
                <i>
                  "A general release does not extend to claims which the party does not know or suspect to exist in his
                  or her favor at the time of executing this release, which if known by him or her must have materially
                  affected his settlement with the other party."
                </i>
              </p>
              <p>
                YOU HEREBY EXPRESSLY WAIVE ALL RIGHTS UNDER CALIFORNIA CIVIL CODE SECTION 1542 (or any statute of like
                effect in the jurisdiction in which you are located). This waiver extends to and includes any and all
                claims, liabilities, injuries, damages, and causes of action that the parties do not presently
                anticipate, know, or suspect to exist, but that may develop, accrue, or be discovered in the future.
              </p>
            </li>
            <li>
              INDEMNIFICATION; HOLD HARMLESS.
              <p>
                You agree to indemnify, defend, and hold MealPal, its affiliated companies, and its suppliers and
                partners harmless from any claims, losses, damages, liabilities, including attorneys’ fees, arising out
                of your misuse of the Site, violation of these Terms, violation of the rights of any other person or
                entity, or any breach of the foregoing representations, warranties, and covenants.
              </p>
            </li>
            <li>
              LIMITATION OF LIABILITY AND DAMAGES.
              <p>
                You agree to indemnify, defend, and hold MealPal, its affiliated companies, and its suppliers and
                partners harmless from any claims, losses, damages, liabilities, including attorneys’ fees, arising out
                of your misuse of the Site, violation of these Terms, violation of the rights of any other person or
                entity, or any breach of the foregoing representations, warranties, and covenants.
              </p>
              <p>
                MEALPAL’S LIABILITY TO YOU IS LIMITED TO $50 USD OR THE AMOUNTS, IF ANY, PAID BY YOU TO MEALPAL UNDER
                THIS AGREEMENT IN THE THREE MONTHS IMMEDIATELY PRIOR TO THE EVENT FIRST GIVING RISE TO THE CLAIM,
                WHICHEVER IS MORE. THE FOREGOING LIMITATIONS WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                LAW.
              </p>
            </li>
            <li>
              ARBITRATION AGREEMENT
              <p>PLEASE READ THE FOLLOWING CAREFULLY:</p>
              <ol type="a">
                <li>
                  <strong>Arbitration.</strong> This Arbitration Agreement facilitates the prompt and efficient
                  resolution of any disputes that may arise between you and MealPal. Arbitration is a form of private
                  dispute resolution in which parties to a contract agree to submit their disputes and potential
                  disputes to a neutral third person (called an arbitrator) for a binding decision, instead of having
                  such dispute(s) decided in a lawsuit, in court, by a judge or jury trial. You have the right to
                  opt-out of this Arbitration Agreement (as explained below), which means you would retain your right to
                  litigate your disputes in a court, either before a judge or jury.
                  <br />
                  <br />
                  <p>
                    Please read this Arbitration Agreement carefully. It provides that all disputes between you and
                    MealPal shall be resolved by binding individual arbitration. Arbitration replaces the right to go to
                    court. In the absence of this arbitration agreement, you may otherwise have a right or opportunity
                    to bring claims in a court, before a judge or jury, and/or to participate in or be represented in a
                    case filed in court by others (including, but not limited to, className actions). EXCEPT AS
                    OTHERWISE PROVIDED, ENTERING INTO THIS ARBITRATION AGREEMENT CONSTITUTES A WAIVER OF YOUR RIGHT TO
                    LITIGATE CLAIMS IN COURT AND ALL OPPORTUNITY TO BE HEARD BY A JUDGE OR JURY. There is no judge or
                    jury in arbitration, and court review of an arbitration award is limited. The arbitrator must follow
                    this Arbitration Agreement and can award the same damages and relief as a court (including
                    attorney’s fees).
                  </p>
                  <p>
                    For the purpose of this Arbitration Agreement, “MealPal” means MealPal and its parents,
                    subsidiaries, and affiliated companies, and each of their respective officers, directors, employees,
                    and agents.
                  </p>
                  <p>
                    You understand and agree that, by entering into this Arbitration Agreement, you and MealPal are each
                    agreeing to arbitration instead of the right to a trial before a judge or jury in a public court. In
                    the absence of this Arbitration Agreement, you and MealPal might otherwise have had a right or
                    opportunity to bring Disputes in a court, before a judge or jury, and/or to participate or be
                    represented in a case filed in court by others (including className actions). Except as otherwise
                    provided below, you give up those rights. Other rights that you would have if you went to court,
                    such as the right to appeal and to certain types of discovery, may be more limited in arbitration.
                    The right to appellate review of an arbitrator’s decision is much more limited than in court, and in
                    general an arbitrator’s decision may not be appealed for errors of fact or law. YOU AGREE THAT,
                    EXCEPT AS PROVIDED BELOW, ANY AND ALL DISPUTES BETWEEN YOU AND MEALPAL (WHETHER OR NOT SUCH DISPUTE
                    INVOLVES A THIRD PARTY) WITH REGARD TO YOUR RELATIONSHIP WITH MEALPAL, INCLUDING WITHOUT LIMITATION
                    DISPUTES RELATED TO THESE TERMS, YOUR USE OF THE SITE, AND/OR RIGHTS OF PRIVACY AND PUBLICITY, WILL
                    BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION UNDER THE AMERICAN ARBITRATION ASSOCIATION'S RULES
                    FOR ARBITRATION OF CONSUMER-RELATED DISPUTES AND YOU AND MEALPAL HEREBY EXPRESSLY WAIVE TRIAL BY
                    JURY; PROVIDED, HOWEVER, THAT TO THE EXTENT THAT YOU HAVE IN ANY MANNER VIOLATED OR THREATENED TO
                    VIOLATE OUR INTELLECTUAL PROPERTY RIGHTS, WE MAY SEEK INJUNCTIVE OR OTHER APPROPRIATE RELIEF IN ANY
                    STATE OR FEDERAL COURT IN THE STATE OF NEW YORK. DISCOVERY AND RIGHTS TO APPEAL IN ARBITRATION ARE
                    GENERALLY MORE LIMITED THAN IN A LAWSUIT, AND OTHER RIGHTS THAT YOU AND WE WOULD HAVE IN COURT MAY
                    NOT BE AVAILABLE IN ARBITRATION. YOU ARE GIVING UP YOUR RIGHT TO PARTICIPATE AS A CLASS
                    REPRESENTATIVE OR CLASS MEMBER ON ANY CLASS CLAIM YOU MAY HAVE AGAINST US INCLUDING ANY RIGHT TO
                    CLASS ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL ARBITRATIONS. You also agree not to participate
                    in claims brought in a private attorney general or representative capacity, or consolidated claims
                    involving another person's account, if we are a party to the proceeding. This dispute resolution
                    provision will be governed by the Federal Arbitration Act and not by any state law concerning
                    arbitration. In the event the American Arbitration Association is unwilling or unable to set a
                    hearing date within one hundred and sixty (160) days of filing the case, then either we or you can
                    elect to have the arbitration administered instead by the Judicial Arbitration and Mediation
                    Services. Judgment on the award rendered by the arbitrator may be entered in any court having
                    competent jurisdiction. Any provision of applicable law notwithstanding, the arbitrator will not
                    have authority to award damages, remedies or awards that conflict with these Terms. You agree that
                    regardless of any statute or law to the contrary, any claim or cause of action arising out of,
                    related to or connected with the use of the Services or these Terms must be filed within one (1)
                    year after such claim of action arose or be forever banned.
                  </p>
                </li>
                <li>
                  <strong>Pre-Arbitration Dispute Resolution.</strong> For all Disputes, whether pursued in court or
                  arbitration, you must first give MealPal an opportunity to resolve the Dispute. You must commence this
                  process by mailing written notification to MealPal, Legal Department, 330 7th Ave, New York, NY 10001.
                  That written notification must include (1) your name, (2) your address, (3) a written description of
                  the Dispute, and (4) a description of the specific relief you seek. If MealPal does not resolve the
                  Dispute to your satisfaction within 45 days after it receives your written notification, you may
                  pursue your Dispute in arbitration. You may pursue your Dispute in a court only under the
                  circumstances described below.
                </li>
                <li>
                  <strong>Exclusions From Arbitration And Your Right To Opt Out.</strong> Notwithstanding the above, you
                  may choose to pursue a Dispute in court and not by arbitration if (a) the Dispute qualifies, it may be
                  initiated in small claims court in your state of residence; or (b) YOU OPT-OUT OF THESE ARBITRATION
                  PROCEDURES WITHIN 30 DAYS FROM THE DATE THAT YOU FIRST CONSENT TO THIS AGREEMENT (the “Opt-Out
                  Deadline”). You may opt out of this Arbitration Agreement by mailing written notification to MealPal,
                  Legal Department, 330 7th Ave, New York, NY 10001. Your written notification must include (1) your
                  name, (2) your address, and (3) a clear statement that you do not wish to resolve disputes with
                  MealPal through arbitration. Your decision to opt-out of this Arbitration Agreement will have no
                  adverse effect on your relationship with MealPal. Any opt-out request received after the Opt-Out
                  Deadline will not be valid and you must pursue your Dispute in arbitration or small claims court.
                </li>
                <li>
                  <strong>Arbitration Procedures.</strong> If this Arbitration Agreement applies and the Dispute is not
                  resolved as provided above (“Pre-Arbitration Claim Resolution”) either you or MealPal may initiate
                  arbitration proceedings. The American Arbitration Association (“AAA”), www.adr.org, will arbitrate all
                  Disputes, and the arbitration will be conducted before a single arbitrator. The arbitration shall be
                  commenced as an individual arbitration, and shall in no event be commenced as a className arbitration.
                  All issues shall be for the arbitrator to decide, including the scope of this Arbitration Agreement.
                  For arbitration before the AAA, for Disputes of less than $75,000 USD, the AAA’s Supplementary
                  Procedures for Consumer-Related Disputes will apply; for Disputes involving $75,000 USD or more, the
                  AAA’s Commercial Arbitration Rules will apply. In either instance, the AAA’s Optional Rules For
                  Emergency Measures Of Protection shall apply. The AAA rules are available at www.adr.org or by calling
                  1-800-778-7879. This Arbitration Agreement governs in the event it conflicts with the applicable
                  arbitration rules. Under no circumstances will className action procedures or rules apply to the
                  arbitration. Because your contract with MealPal, the Terms of Use, and this this Arbitration Agreement
                  concern interstate commerce, the Federal Arbitration Act (“FAA”) governs the arbitrability of all
                  Disputes. However, the arbitrator will apply applicable substantive law consistent with the FAA and
                  the applicable statute of limitations or condition precedent to suit.
                </li>
                <li>
                  <strong>Arbitration Award.</strong> The arbitrator may award on an individual basis any relief that
                  would be available pursuant to applicable law, and will not have the power to award relief to, against
                  or for the benefit of any person who is not a party to the proceeding. The arbitrator shall make any
                  award in writing but need not provide a statement of reasons unless requested by a party. Such award
                  by the arbitrator will be final and binding on the parties, except for any right of appeal provided by
                  the FAA, and may be entered in any court having jurisdiction over the parties for purposes of
                  enforcement.
                </li>
                <li>
                  <strong>Location of Arbitration.</strong> You may initiate arbitration in either New York, New York,
                  or in the federal judicial district that includes your address that you provide in your written
                  notification of Pre-Arbitration Dispute Resolution. In the event that MealPal initiates an
                  arbitration, it may only do so in the federal judicial district that includes your address that you
                  provide in your written notification of Pre-Arbitration Dispute Resolution.
                </li>
                <li>
                  <strong>Payment of Arbitration Fees and Costs.</strong> MealPal will pay all arbitration filing fees
                  and arbitrator’s costs and expenses upon your written request given prior to the commencement of the
                  arbitration. You are responsible for all additional fees and costs that you incur in the arbitration,
                  including, but not limited to, attorneys or expert witnesses. Fees and costs may be awarded as
                  provided pursuant to applicable law. In addition to any rights to recover fees and costs under
                  applicable law, if you provide notice and negotiate in good faith with MealPal as provided in the
                  section above titled “Pre-Arbitration Dispute Resolution” and the arbitrator concludes that you are
                  the prevailing party in the arbitration, you will be entitled to recover from MealPal your actual and
                  reasonable attorney’s fees and costs as determined by the arbitrator.
                </li>
                <li>
                  <strong>Severability.</strong> If any clause within this Arbitration Agreement (other than the Class
                  Action Waiver clause above) is found to be illegal or unenforceable, that clause will be severed from
                  this Arbitration Agreement, and the remainder of this Arbitration Agreement will be given full force
                  and effect. If the Class Action Waiver clause is found to be illegal or unenforceable, then this
                  entire Arbitration Agreement will be unenforceable and the Dispute will be decided by a court.
                </li>
                <li>
                  <strong>Continuation.</strong> This Arbitration Agreement shall survive the termination of your
                  contract with MealPal and your use of the MealPal Site and services.
                </li>
              </ol>
            </li>
            <li>
              MISCELLANEOUS
              <ol type="a">
                <li>
                  <strong>Choice of Law; Forum.</strong> These Terms shall be governed in all respects by the laws of
                  the State of New York, without regard to conflict of law provisions. You agree that any claim or
                  dispute you may have against MealPal must be resolved by a court located in New York, except as
                  otherwise agreed by the parties or as described in the Arbitration Agreement paragraph above. You
                  agree to submit to the personal jurisdiction of the courts located within New York, New York for the
                  purpose of litigating all such claims or disputes.
                </li>
                <li>
                  <strong>Assignment.</strong> We may assign, transfer, delegate, or sublicense our rights and
                  obligations under these Terms. The Terms will inure to the benefit of our successors, assigns and
                  licensees. You may not assign, transfer, delegate, or sublicense your rights and obligations under
                  these Terms.
                </li>
                <li>
                  <strong>Severability.</strong> If any provision of these Terms shall be unlawful, void, or for any
                  reason unenforceable, then that provision will be deemed severable from these Terms and will not
                  affect the validity and enforceability of any remaining provisions or limited to the minimum extent
                  necessary so that these Terms will otherwise remain in full force and effect and enforceable.
                </li>
                <li>
                  <strong>Force Majeure.</strong> MealPal shall not be liable for any failure to perform our obligations
                  hereunder where such failure results from any cause beyond our reasonable control, including, without
                  limitation, mechanical, electronic or communications failure or degradation.
                </li>
                <li>
                  <strong>Headings.</strong> The heading references herein are for convenience purposes only, do not
                  constitute a part of these Terms, and will not be deemed to limit or affect any of the provisions
                  hereof.
                </li>
                <li>
                  <strong>Entire Agreement.</strong> These Terms, the Privacy Policy, the Arbitration Agreement, and any
                  applicable Additional Terms, as each may be amended as set forth herein, are the entire agreement
                  between you and MealPal relating to the subject matter herein.
                </li>
                <li>
                  <strong>Claims; Statute of Limitations.</strong> YOU AND MEALPAL AGREE THAT ANY CAUSE OF ACTION
                  ARISING OUT OF OR RELATED TO THESE TERMS OR THE SITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE
                  OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
                </li>
                <li>
                  <strong>Disclosures.</strong> The services hereunder are offered by MealPal, Inc., located at: 330 7th
                  Ave, New York, NY 10001 and email:
                  <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>. If you are a California resident, you may
                  have this same information emailed to you by sending a letter to the foregoing address with your email
                  address and a request for this information.
                </li>
                <li>
                  <strong>Waiver.</strong> No waiver of any of these Terms by MealPal is binding unless authorized in
                  writing by an executive officer of MealPal. In the event that MealPal waives a breach of any provision
                  of these Terms, such waiver will not be construed as a continuing waiver of other breaches of the same
                  nature or other provisions of these Terms and will in no manner affect the right of MealPal to enforce
                  the same at a later time.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default TermsAndConditions
